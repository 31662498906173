import AmenitiesSlider from "@components/AmenitiesSlider/AmenitiesSlider"
import { AreaguideMoreInfo } from "@components/AreaguideInfo/AreaguideInfo"
import BannerOffplan from "@components/BannerOffplan/BannerOffplan"
import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import { offCurrConvertPrice } from "@components/CurrencyConverter/CurrConvertPrice"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import FloorPlans from "@components/FloorPlans/FloorPlans"
import Layout from "@components/Layout/Layout"
import OffplanAbout from "@components/OffplanAbout/OffplanAbout"
import OffplanBrochure from "@components/OffplanBrochure/OffplanBrochure"
import OffplanImages from "@components/OffplanImages/OffplanImages"
import OffplanNavBar from "@components/OffplanNavBar/OffplanNavBar"
import PaymentPlans from "@components/PaymentPlans/PaymentPlans"
import RegisterInterestModalWithTrigger from "@components/RegisterInterestModal/RegisterInterestModal"
import RegisterInterestModule from "@components/RegisterInterestModule/RegisterInterestModule"
import { seoConData } from "@components/SearchResults/SearchFilters/filterOptions"
import TileBlock from "@components/TileBlock/TileBlock"
import VideoBanner from "@components/VideoBanner/VideoBanner"
import Seo from "@components/seo"
import useHasScrolledBanner from "@hooks/useHasScrolledBanner"
import { offplanResultsURL } from "@lib/urls"
import { capitalize, slugToTitle } from "@lib/utils"
import { useGlobalStore } from "@stores/globalStore"
import { graphql, navigate } from "gatsby"
import React, { useEffect } from "react"
import { Container } from "react-bootstrap"

function OffplanTemplate({ data }) {
  const offplan = data.allProjects?.nodes?.length > 0 ? data.allProjects?.nodes[0] : []
  if (!offplan) return null
  if (offplan?.publish === false) {
    if (typeof window !== "undefined") {
    navigate("/new-projects/", { replace: true })
    }
  }
  const scrolled = useHasScrolledBanner()
  const {
    characteristics_module,
    ggfx_results,
    strapi_id,
    location_tile,
    amenities,
    video_module,
  } = offplan

  useEffect(() => {
    if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'product_id': offplan?.strapi_id });
    }
  }, [offplan]);
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);
  const buildingText = offplan?.building_type?.join(", ")
  const { currencyType } = useGlobalStore()

  function convertToInternationalCurrencySystem(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
      : Math.abs(Number(labelValue)) >= 1.0e+6
      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
      : Math.abs(Number(labelValue)) >= 1.0e+3
      ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"
      : Math.abs(Number(labelValue));
  }

  const conPrice = offCurrConvertPrice(offplan?.price);
  const whatsPrice = (offplan?.price && (typeof offplan?.price === "number" || !isNaN(Number(offplan?.price.toString().replace(/,/g, ""))))) ? currencyType?.value + convertToInternationalCurrencySystem(conPrice.replace(/,/g, "")) : offplan?.price ? offplan?.price : "TBA"
  const projectURL = typeof window !== "undefined" && window.location.href 
  const propertyURL = `${offplanResultsURL + offplan?.slug}/`
        const { leadSource } = useGlobalStore()
        let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
        let leadName = seoSourceName[0]?.name === "Organic" ? "Browser Direct" : seoSourceName[0]?.name
        const utmId = seoSourceName && seoSourceName?.length > 0 ? `?utm_source=${leadName}` : ""
  return (
    <>
    <Layout
      pageData={{
        page_class: "team-detail-page",
        layout: "team-detail",
      }}
      transparentHeader
    >
      <BannerOffplan offplan={offplan} />
      <OffplanNavBar offplan={offplan} />
      <OffplanAbout offplan={offplan} />
      <OffplanImages offplan={offplan} />
      <TileBlock
        module={characteristics_module}
        ggfx_results={ggfx_results}
        strapiID={strapi_id}
        className="characteristics-module"
        imagename="off-plan.characteristics_module_image.tile_img"
      />
      <FloorPlans offplan={offplan} />
      <AmenitiesSlider amenities={amenities} />
      <TileBlock
        module={location_tile}
        ggfx_results={ggfx_results}
        strapiID={strapi_id}
        className="location-module"
        id="offplan-location"
        imagename="off-plan.location_tile_image.tile_img"
      />
      {offplan.payment_plans?.length > 0 || offplan.add_plan?.length > 0 ?
      <PaymentPlans offplan={offplan} /> : null}
      <OffplanBrochure offplan={offplan} />
      <Container
        className="video-banner-container section-l-m"
        id="offplan-video"
      >
        <VideoBanner
          bannerVideo={video_module}
          ggfx_results={ggfx_results}
          strapiID={strapi_id}
          imagename="off-plan.video_module_thumbnail.thumbnail"
        />
      </Container>
      <AreaguideMoreInfo more_info={offplan.more_info} title={offplan.title} />
      <RegisterInterestModule offplan={offplan} 
      message={`Hello Provident,\n\nI would like to know more about this Off Plan Project:\n\n• Project Name: ${offplan?.title}\n• Project Location: ${offplan?.display_address}\n• Developer: ${slugToTitle(offplan?.developer)}\n• Type: ${capitalize(buildingText)}\n• Starting Price:  ${whatsPrice}\n• Link: ${projectURL+utmId}\n\nModifying this message will prevent it from being sent to the agent.`}
      propertyURL={propertyURL}
 />
    </Layout>
    {scrolled &&
    <FloatingCTAShell>
<div className="cta-section">
          <BrochureModalWithTrigger offplan={offplan} />
          <RegisterInterestModalWithTrigger offplan={offplan} />
        </div>
    </FloatingCTAShell>}
    </>
  )
}

export default OffplanTemplate

export const Head = ({ data }) => {
  const offplan = data.allProjects?.nodes?.length > 0 ? data.allProjects?.nodes[0] : [] 

  if (!offplan) return null

  const building = offplan?.building_type?.[0] || "property"
  const action = "for sale"
  const bedrooms = offplan?.max_bedrooms || offplan?.min_bedrooms
  const bedroomsText = bedrooms > 1 ? "bedrooms" : "bedroom"
  const title = offplan?.title
  const price = offplan?.price

  let showPrice = true

  if (!price || Number(price) === 0 || price === "N/A") {
    showPrice = false
  }

  const titleText = `Off plan ${building} ${action} with ${bedrooms} ${bedroomsText} in ${title}${
    showPrice ? ` at AED ${price}` : ""
  }`
  const descriptionText = `Know the details of off plan ${building} ${action} with ${bedrooms} ${bedroomsText} in ${title}${
    showPrice ? ` at AED ${price}` : ""
  }. Book a viewing to get assistance in finding the right ${building} for you.`

  const metaTitle = offplan?.seo?.metaTitle || titleText
  const metaDescription = offplan?.seo?.metaDescription || descriptionText
  const imgUrl = offplan?.tile_image?.url ? offplan?.tile_image?.url : offplan?.media_images?.length > 0 ? offplan?.media_images?.url : offplan?.images?.length> 0 && offplan?.images[0]?.url ? offplan?.images[0]?.url : offplan?.images?.length> 0 && offplan?.images[0]?.srcUrl 
  return (
    <Seo
      title={metaTitle}
      description={metaDescription}
      img={imgUrl}
    />
  )
}

export const query = graphql`
  query ($strapi_id: Int) {
  allProjects(filter: {strapi_id: {eq: $strapi_id}}) {
   nodes {
        ...offplanFragment
      }
    }
  }
`
